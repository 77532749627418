import * as React from 'react';
import * as CSS from 'csstype';

const Grocerly = () => {
  return (
    <div>
      <h1>Grocerly</h1>
    </div>
  );
};

export default Grocerly;
